import React from 'react';
import styles from './webinar.module.scss';
/**
 *
 *
 * @return {*}
 */
function WebinarHeader() {
  return (
    <div className={styles.trialWebinarHeader}>
      <h1>WebinarHeader</h1>
    </div>
  );
}

export default WebinarHeader;
