import React from 'react';
import WebinarHeader from './Header';
import VideoPlayer from './VideoPlayer';

/**
 *
 *
 * @return {*}
 */
function TrialWebinar() {
  return (
    <>
      <WebinarHeader/>
      {/* <h1>Trial Webinar</h1> */}
      <VideoPlayer/>
    </>
  );
}

export default TrialWebinar;
