import React, {useState, useEffect} from 'react';
import Player from '@vimeo/player';
import styles from './webinar.module.scss';

/**
 *
 *
 * @return {*}
 */
function VideoPlayer() {
  const [playerInfo, setPlayerInfo] = useState({});

  useEffect(() => {
    const url = 'https://vimeo.com/551463193';
    const player = new Player('video-player', {
      url,
      background: 1,
      autoplay: true,
      loop: 0,
      controls: true,
    });
    setPlayerInfo(player);
  }, []);

  useEffect(() => {
    if (Object.keys(playerInfo).length > 0) {
      playerInfo?.on('play', function(evt) {
        playerInfo.setVolume(1);
        console.log('played', evt);
      });

        playerInfo?.on('pause', function(evt) {
          console.log('paused', evt);
        });

      playerInfo?.getDuration().then(function(duration) {
        console.log('duration', duration);
      });

      playerInfo?.on('ended', function(evt) {
        console.log('ended', evt);
      });
    }
  }, [playerInfo]);

  return (
    <div className={styles.videoPlayer}>
      <h1>Diploma in Photoshop</h1>
      <h2>Lesson 2: What is Photoshop</h2>
      <div className={styles.videoAttributes}>
        <div className={styles.videoItem}>
          <div id="video-player"></div>
        </div>
        <div className={styles.videoItem}>
          <div className={styles.videoInfo}>
            <p>8 Lessons (4 hours 30 mins)</p>
            <ul>
              <li>Lesson 1: Introduction</li>
              <li>Lesson 2: What is Photoshop</li>
              <li>Lesson 3: What is Photoshop</li>
              <li>Lesson 4: Introduction</li>
              <li>Lesson 5: Introduction</li>
              <li>Lesson 6: Introduction</li>
              <li>Lesson 7: Introduction</li>
              <li>Lesson 8: Introduction</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
